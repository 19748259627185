<template>
  <div>
    <b-row class="mb-3">
      <b-col md="12 clearfix mt-3">
        <div v-if="name"><h4 class="d-inline">Assign Services To: {{name}} <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="To disable vendor from project make all services inactive"></i></h4></div>
        <div v-if="name" class="clearfix mt-3" style="width: max-content;">
        <b-button variant="success" class="pull-right btn-sm" v-b-modal.exportService> Export Services To Existing Project <i class="fa fa-plus"></i></b-button>
        <b-button variant="success" class="pull-right mr-2 btn-sm" v-b-modal.copyService> Import Services From Existing Project <i class="fa fa-plus"></i></b-button>
        <b-button variant="success" class="pull-right mr-2 btn-sm" v-b-modal.import> Import Services From CSV <i class="fa fa-plus"></i></b-button>
        <b-button variant="success" class="pull-right mr-2 btn-sm" v-b-modal.addService> Add Service <i class="fa fa-plus"></i></b-button>
        </div>
      </b-col>
    </b-row>
    <v-client-table v-if="name" ref ="Servicedatatable" class="statusTable" :columns="columnsServices" :data="wtsVendorServiceData" :options="optionsServices" :theme="theme" id="dataTable">
      <div slot="status" slot-scope="props">
        <span v-if="props.row.status === 0">
          <button class="btn btn-sm btn-success w-100">Active</button>
        </span>
        <span v-if="props.row.status !== 0">
          <button class="btn btn-sm btn-danger w-100">Inactive</button>
        </span>
      </div>
      <div slot="uom1" slot-scope="props">
        ${{props.row.pu1}}/{{props.row.uom1}}
      </div>
        <div slot="volume" slot-scope="props">
      {{props.row.volume == 'Y' ? props.row.volume : 'N'}}
      </div>
      <div slot="uom2" slot-scope="props">
        <span v-if="props.row.pu2">${{props.row.pu2}}/{{props.row.uom2}}</span>
      </div>
      <div slot="uom3" slot-scope="props">
        <span v-if="props.row.pu3">${{props.row.pu3}}/{{props.row.uom3}}</span>
      </div>

      <div slot="actions" slot-scope="props">
        <b-button variant="primary" class="mr-2 btn-sm" v-b-modal.editService @click=" props.row.volume == 'N' ? uom_volume_status = false : uom_volume_status = true; sendEditService(props.row)"><i class="fa fa-edit"></i></b-button>
      </div>

      <div slot="delete" slot-scope="props">
        <b-button variant="danger" class="mr-2 btn-sm" @click="sendDeleteService(props.row)"><i class="fa fa-trash"></i></b-button>
      </div>
    </v-client-table>

    <b-modal id="editService" ref="editService" title="Edit Service" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="editServiceValidator('editService')" data-vv-scope="editService" class="pb-2">
        <b-row class="mb-3">
          <b-col md="12" class="mb-1">
            <label for="">Service Name:</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-hashtag"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-validate="'required'" name="servicename" v-model="servicename" class="form-control" placeholder="Service Name" />
            </b-input-group>
            <i v-show="errors.has('editService.servicename')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('editService.servicename')">The service name is required.</span>
          </b-col>
          <b-col md="12" class="mb-1">
            <label for="">Service Code:</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-hashtag"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-validate="'required'" name="type" v-model="type" class="form-control" placeholder="Service Code" />
            </b-input-group>
            <i v-show="errors.has('editService.type')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('editService.type')">The service code is required.</span>
          </b-col>

          <b-col md="12" class="mb-1">
Uom is volume?<br /><input  type="checkbox" @click="uom_volume_status = true">Yes
<input type="checkbox" @click="uom_volume_status = false">No
<br />
             </b-col>

          <b-col cols="6" class="mb-1">
            <label for="">UOM: <i class="fa fa-question-circle" v-b-tooltip.hover title="Unit Of Measurement"></i></label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-hashtag"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-validate="'required'" name="uom1" v-model="uom1" class="form-control" placeholder="" />
            </b-input-group>
            <i v-show="errors.has('editService.uom1')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('editService.uom1')">The unit of measurement required.</span>
          </b-col>
          <b-col cols="6" class="mb-1">
            <label for="">Price / Unit:</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-validate="'required|decimal:3'" name="pu1" v-model="pu1" class="form-control" placeholder="" />
            </b-input-group>
            <i v-show="errors.has('editService.pu1')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('editService.pu1')">The price is required. Numbers only. Ex: $59.99</span>
          </b-col>

          <b-col cols="6" class="mb-1" >
            <label for="">UOM 2: </label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-hashtag"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" name="uom2" v-model="uom2" class="form-control" placeholder="" />
            </b-input-group>
          </b-col>
          <b-col cols="6" class="mb-1" >
            <label for="">Price / Unit: </label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-validate="'decimal:3'" name="pu2" v-model="pu2" class="form-control" placeholder="" />
            </b-input-group>
            <i v-show="errors.has('editService.pu2')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('editService.pu2')"> Numbers only. Ex: $59.99</span>
          </b-col>

          <b-col cols="6" class="mb-1">
            <label for="">UOM 3: </label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-hashtag"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" name="uom3" v-model="uom3" class="form-control" placeholder="" />
            </b-input-group>
          </b-col>
          <b-col cols="6" class="mb-1">
            <label for="">Price / Unit: </label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-validate="'decimal:3'" name="pu3" v-model="pu3" class="form-control" placeholder="" />
            </b-input-group>
            <i v-show="errors.has('editService.pu3')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('editService.pu3')">The price is required. Numbers only. Ex: $59.99</span>
          </b-col>
          <b-col cols="12" class="">
            <label for="">Currency</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-select v-model="currency">
                <option value="CAD" selected>CAD</option>
                <option value="USD">USD</option>
              </b-select>
            </b-input-group>
            <i v-show="errors.has('editService.currency')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('editService.currency')">The price is required. Numbers only. Ex: $59.99</span>
          </b-col>

          <b-col cols="12" class="mb-2 mt-2">
            <b-input-group class="mb-2">
              <b-form-checkbox class="ml-2 mt-1" v-model="status" value="0" unchecked-value="1"><p class="ml-3 mb-0"><span v-if="status == 0">Active</span><span v-if="status != 0"> Inactive</span></p></b-form-checkbox>
            </b-input-group>
          </b-col>
          <b-col v-show="error" cols="12" class="my-1 mb-2">
            <i v-show="error" class="fa fa-exclamation-triangle mt-2"></i><span v-show="error" class="help is-danger">Service error. Cannot have duplicates. Please contact admin.</span></span>
          </b-col>

          <b-col md="12">
            <b-button variant="success" class="mt-2" type="submit" block>Edit Service <i class="fa fa-edit"></i></b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <b-modal @shown="loadProjectsForImport(), project_id = [], successServices = [],serviceErrors = [], loadingCount = 0, progressBarServicesCount = 0, progressBar = false, importing = true, projectid = [], vendorSelection = []" @hidden="loadData(), importing = false, progressBar = false" id="copyService" ref="copyService" title="Import Services" title-tag="h2" centered size="lg" hide-footer>
      <b-row class="mb-3">
        <b-col md="12" class="mb-3">
          <h5>Select Project</h5>
          <multiselect v-model="project_id" placeholder="Search For Project" tag-placeholder="Search For Project" track-by="projectid" :options="projectData" :multiple="true" :close-on-select="true" :clear-on-select="true" :preserve-search="true" :taggable="false" class="w-100" label="projectname" :preselect-first="false">
          </multiselect>
        </b-col>
        <b-col md="12">
          <b-button variant="success" :disabled="progressBar" @click="CopyServiceForSelectedProject()"  type="submit" block>Import Services <i class="fa fa-file-import"></i></b-button>
        </b-col>

        <div class="col-md-12 mt-4" v-show="progressBar">
          <h4>Progress:</h4>
        </div>
        <div class="col-md-12">
          <b-progress class="mt-2" :max="progressBarServicesCount" height="2rem" style="font-size:0.8rem;" show-value v-show="progressBar">
            <b-progress-bar v-if="loadingCount == progressBarServicesCount" :value="loadingCount" :label="loadingCount + '/' + progressBarServicesCount + ' (100%)'" variant="success"></b-progress-bar>
            <b-progress-bar v-else :value="loadingCount" :label="loadingCount + '/' + progressBarServicesCount + ' (' + `${((loadingCount / progressBarServicesCount) * 100).toFixed(2)}%` + ')'" variant="success"></b-progress-bar>
          </b-progress>
          <p class="mt-3" v-show="progressBar">Please do not close this window until 100% or it may be unsuccessful.</p>
        </div>

        <div class="col-md-12 mt-2" style="max-height:300px;overflow:scroll" v-if="successServices.length > 0">
          <h5>Successful: </h5>
          <ul>
            <li v-for="success in successServices">
              {{success}}
            </li>
          </ul>
        </div>
        <div class="col-md-12 mt-2" style="max-height:300px;overflow:scroll" v-if="serviceErrors.length > 0">
          <h5>Unsuccessful (Possible Duplicates): </h5>
          <ul>
            <li v-for="error in serviceErrors">
              {{error}}
            </li>
          </ul>
          
        </div>
      </b-row>
    </b-modal>

    <b-modal @shown="loadProjectsForImport(), successServices = [], project_id = [], serviceErrors = [], projectid = [], vendorSelection = [], loadingCount = 0, progressBarServicesCount = 0, progressBar = false" @hidden="loadData(), progressBar = false" id="exportService" ref="exportService" title="Export Services" title-tag="h2" centered size="lg" hide-footer>
      <b-row class="mb-3">
        <b-col md="12" class="mb-3">
          <h5>Select Project</h5>
          <multiselect v-model="project_id" placeholder="Search For Project" tag-placeholder="Search For Project" track-by="projectid" :options="projectData" :multiple="true" :close-on-select="true" :clear-on-select="true" :preserve-search="true" :taggable="false" class="w-100" label="projectname" :preselect-first="false">
          </multiselect>
        </b-col>
        <!-- <b-col class="mt-3" md="12">
          <h5>Select Vendor</h5>
          <multiselect v-model="vendorSelection" @input="successServices = [],serviceErrors = [], loadingCount = 0, progressBarServicesCount = 0, progressBar = false" placeholder="Search For Project" tag-placeholder="Search For Vendor" track-by="vid" :options="vendors" :multiple="false" :close-on-select="true" :clear-on-select="true" :preserve-search="true" :taggable="true" class="w-100" label="company_name" :preselect-first="true">
          </multiselect>
          <p class="my-3"><strong>**Duplicate services will not be imported.</strong></p>
        </b-col> -->
        <b-col md="12">
          <b-button variant="success" :disabled="progressBar" @click="exportServicesForMultipleProjecs()" type="submit" block>Export Services <i class="fa fa-file-import"></i></b-button>
        </b-col>
        <div class="col-md-12 mt-4" v-show="progressBar">
          <h4>Progress:</h4>
        </div>
        <div class="col-md-12">
          <b-progress class="mt-2" :max="progressBarServicesCount" height="2rem" style="font-size:0.8rem;" show-value v-show="progressBar">
            <b-progress-bar v-if="loadingCount == progressBarServicesCount" :value="loadingCount" :label="loadingCount + '/' + progressBarServicesCount + ' (100%)'" variant="success"></b-progress-bar>
            <b-progress-bar v-else :value="loadingCount" :label="loadingCount + '/' + progressBarServicesCount + ' (' + `${((loadingCount / progressBarServicesCount) * 100).toFixed(2)}%` + ')'" variant="success"></b-progress-bar>
          </b-progress>
          <p class="mt-3" v-show="progressBar">Please do not close this window until 100% or it may be unsuccessful.</p>
        </div>

        <div class="col-md-12 mt-2" style="max-height:300px;overflow:scroll" v-if="successServices.length > 0">
          <h5>Successful: </h5>
          <ul>
            <li v-for="success in successServices">
              {{success}}
            </li>
          </ul>
        </div>
        <div class="col-md-12 mt-2" style="max-height:300px;overflow:scroll" v-if="serviceErrors.length > 0">
          <h5>Unsuccessful (Possible Duplicates): </h5>
          <ul>
            <li v-for="error in serviceErrors">
              {{error}}
            </li>
          </ul>
        </div>

      </b-row>
    </b-modal>

    <b-modal @shown="clearImport(), loadingCount = 0, progressBarServicesCount = 0, progressBar = false" @hidden="loadData(), progressBar = false" id="import" hide-footer ref="import" title="Import Services" title-tag="h2" centered size="lg">
      <div class="parse mt-2 mb-4 row">
        <!-- <h4>Loading Count: {{loadingCount}}</h4> -->
        <!-- <b-col md="12">
          <h5>Select Project</h5>
          <multiselect v-model="project_id" placeholder="Search For Project" tag-placeholder="Search For Project" track-by="projectid" :options="projectData" :multiple="true" :close-on-select="true" :preserve-search="true" :taggable="false" class="w-100" label="projectname" :preselect-first="false">
          </multiselect>
        </b-col> -->

        <div class="col-md-6">
          <h5>CSV Files Only</h5>
          <input
            class="mt-2"
            id="fileInput"
            type="file"
            ref="csvFile"
            @change="upload">
        </div>
        <div v-if="doc.length > 0" class="col-md-6">
          <h5>Import</h5>
          <button @click="importServices(doc)" class="btn btn-primary">Import</button>
        </div>

        <div v-if="doc.length === 0" class="col-md-6">
          <h5>Template</h5>
          <a href="/templates/Service_template.csv" class="btn btn-primary">Download Template <i class="fa fa-file"></i></a>
        </div>

        <div class="col-md-12 mt-2">
          <b-progress class="mt-2" :max="progressBarServicesCount" height="2rem" style="font-size:0.8rem;" show-value v-show="progressBar">
            <b-progress-bar v-if="loadingCount == progressBarServicesCount" :value="loadingCount" :label="loadingCount + '/' + progressBarServicesCount + ' (100%)'" variant="success"></b-progress-bar>
            <b-progress-bar v-else :value="loadingCount" :label="loadingCount + '/' + progressBarServicesCount + ' (' + `${((loadingCount / progressBarServicesCount) * 100).toFixed(2)}%` + ')'" variant="success"></b-progress-bar>
          </b-progress>
          <p class="mt-2" v-show="progressBar">Please do not close this window until 100% or it may be unsuccessful.</p>
        </div>
 <!--  -->
        <div class="col-md-12 mt-2" style="max-height:300px;overflow:scroll" v-if="successServices.length > 0">
          <h5>Successful: </h5>
          <ul>
            <li v-for="success in successServices">
              {{success}}
            </li>
          </ul>
        </div>
        <!--  -->
        <div class="col-md-12 mt-2" style="max-height:300px;overflow:scroll" v-if="serviceErrors.length > 0">
          <h5>Unsuccessful (Duplicate Services): </h5>
          <ul>
            <li v-for="error in serviceErrors">
              {{error}}
            </li>
          </ul>
          
         <h5 v-if="errorDoc.length > 1">Unsuccessful (Price for services are not valid): </h5>
          <ul v-if="errorDoc.length > 1">
            <li v-for="error in errorDoc">
              {{error.Service}}
            </li>
          </ul>
      </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {ClientTable} from 'vue-tables-2'
import VueCsvImport from 'vue-csv-import';
import Papa from 'papaparse';
import _ from 'lodash';
import Multiselect from 'vue-multiselect'

import Blob from 'blob'
import FileSaver from 'file-saver'
import { ConcurrencyManager } from 'axios-concurrency';
import promiseLimit from 'promise-limit'
import axios from 'axios'

export default {
  name: 'WtsAddWell',
  props: ['vid', 'name', 'update'],
  components: {
    ClientTable,
    VueCsvImport,
    Multiselect,
  },
  data: function () {
    return {
      parseCsv: [],
      type: '',
      servicename: '',
      serviceid: '',
      uom1: '',
      uom2: '',
      uom3: '',
      pu1: '',
      pu2: '',
      pu3: '',
      status: '',
      error: '',
      currency: "CAD",
      doc: [],
      errorDoc:[],
      csv: '',
      //Services Data Table
      columnsServices: ['status', 'name', 'type', 'uom1', 'uom2', 'uom3', 'currency','volume','actions','delete'],
      optionsServices: {
        headings: {
          'type': 'Code',
          'uom1': 'Price/UOM',
          'uom2': 'Price/UOM',
          'uom3': 'Price/UOM',
           'delete': 'Delete Service',
           'volume': 'Volume'
        },
        sortable: ['status', 'id', 'name', 'type', 'uom1', 'uom2', 'uom3', 'currency', 'afe'],
        filterable: ['status', 'id', 'name', 'type', 'uom1', 'uom2', 'uom3', 'currency', 'afe'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
      },
      services: [],
      theme: 'bootstrap4',
      template: 'default',
      uom_volume_status:false,
      useVuex: true,
      wtsVendorServiceData: [],
      successServices: [],
      serviceErrors: [],
      projectData: [],
      projectid: [],
      vendors: [],
      vendorSelection: [],
      loadingCount: 0,
      progressBarServicesCount: 0,
      progressBar: false,
      importing: false,
      project_id:[]
    }
  },
  watch: {
    vid: function (newVal) {
      if(newVal) {
        this.loadData();
      }
    },
    update: function (newVal) {
      if(newVal) {
        this.loadData();
      }
    }
  },
  methods: {
    loadVendors(project){
      this.$http.get('/wts/get/vas/' + project.projectid)
      .then(response => {
        this.vendors = response.data.result.filter(x => x.vid == this.vid)[0];
      })
      .catch(error => {
      })
    },
    loadVendorsForExport(){
      this.$http.get('/wts/get/vendors/'+ this.projectid.projectid)
      .then(response => {
        this.vendors = response.data.result;
      })
      .catch(error => {
      })
    },
    sendDeleteService(props){

     this.$http.delete('/wts/delete/vas/'+ props.serviceid + '/' + props.vid + '/' + this.$route.params.id).then(res=>{
        this.$toasted.show('Service deleted Succesfully.', {type: 'success', duration: '3000'});
         this.loadData();
     })
     .catch(err=>{  
    this.$toasted.show('Service is in used.', {type: 'error', duration: '3000'});
});
    
    },
    sendEditService(props){
      this.serviceid = props.serviceid;
      this.servicename = props.name;
      this.type = props.type;
      this.uom1 = props.uom1;
      this.uom2 = props.uom2;
      this.uom3 = props.uom3;
      this.pu1 = props.pu1;
      this.pu2 = props.pu2;
      this.pu3 = props.pu3;
      this.currency = props.currency;
      this.status = props.status;
    },
    clearImport(){
      this.successServices = [];
      this.serviceErrors = [];
      this.doc = [];
      this.errorDoc = [];

      const input = this.$refs.csvFile
      input.type = 'text'
      input.type = 'file'
      this.$refs.csvFile.value = null
    },
    upload (e) {
      const that = this
      that.errorDoc = [];
      that.doc = [];

      const fileToLoad = e.target.files[0]

      const reader = new FileReader()
      reader.onload = fileLoadedEvent => {
        Papa.parse(fileLoadedEvent.target.result, {
          header: true,
          complete (results) {
            results.data.map((value,index)=>{
              if(isNaN(value.Price1) || isNaN(value.Price2) || isNaN(value.Price3) || value.Service === ''){                  
                   that.errorDoc.push(value);
              }else{  
               that.doc.push(value);
              }
              
            });
          },
          error (errors) {
          }
        })
      }
      reader.readAsText(fileToLoad)
    },
    job (service) {

      if(this.progressBar == true){

        let price1 = service.Price1;
        let price2 = service.Price2;
        let price3 = service.Price3;

        let data = JSON.stringify({
          projectid: this.$route.params.id,
          type: service.Code,
          name: service.Service,
          uom1: service.UOM1,
          uom2: service.UOM2,
          uom3: service.UOM3,
          uom_volume_status: service.Volume != 'N' && service.Volume ? true : false,
          pu1: price1,
          pu2: price2,
          pu3: price3,
          currency: service.Currency ? service.Currency : 'CAD'
        });

        return this.$http.post('/wts/create/service', data).then((response) => {
          let data = JSON.stringify({
            vid: this.vid,
            projectid: this.$route.params.id,
            serviceid: response.data.id
          })
          this.successServices.push(service.Service)
          this.$http.post('/wts/create/vas', data)
        }).then((response) => {

          this.loadingCount++
          return service.Code;

        }).catch((error) => {
          
          this.serviceErrors.push(service.Service);
        
        });
      }
 
 },
    importServices(services) {
      let serviceSuccess = [];

      this.progressBar = true;
      this.progressBarServicesCount = services.length;

      const that = this;
      var limit = promiseLimit(3)

      Promise.all(services.map((service) => {
        return limit(() => that.job(service))
      })).then(results => {

        const input = this.$refs.csvFile
        input.type = 'text'
        input.type = 'file'
        this.doc = [];
        this.$refs.csvFile.value = null;
        if(this.serviceErrors.lenth > 0){
          this.$toasted.show('Some Services Unsuccessful See Below', {type: 'success', duration: '3000'})
        }
        if(this.successServices.lenth > 0){
          this.$toasted.show('Services Successfully Imported', {type: 'success', duration: '3000'})
        }
      })
    },
    editServiceValidator(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let data = JSON.stringify({
            id: this.serviceid,
            projectid: this.$route.params.id,
            type: this.type,
            name: this.servicename,
            uom1: this.uom1,
            uom2: this.uom2,
            uom3: this.uom3,
            pu1: this.pu1,
            pu2: this.pu2,
            pu3: this.pu3,
            currency: this.currency,
            status: this.status,
            uom_volume_status: this.uom_volume_status
          })
          this.$http.post('/wts/edit/service', data)
          .then(response => {
            if (response.status === 200) {
              this.$toasted.show('Service Edited Successfully', {type: 'success', duration: '3000'})
              this.$refs.editService.hide();
              this.loadData();
              this.error = false;
            }
          })
          .catch(error => {
            this.$toasted.show('Error', {type: 'error', duration: '3000'})
            this.error = true;
          })
          return;
        }
      });
    },
    getServices(){
      this.$http.get('/wts/get/services/' + this.$route.params.id)
      .then(response => {
        this.services = response.data.result;
        this.loadVendorServices();
      })
      .catch(error => {

      })
    },
    loadVendorServices() {
      this.$http.get('/wts/get/vas/' + this.$route.params.id)
      .then(response => {
        var test = response.data.result;
        this.wtsVendorServiceData = test.filter(x=>x.vid === this.vid);

      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
    },
    loadProjects() {
      this.$http.get('/wts/get/projects')
      .then(response => {
       this.projectData = [{projectid: 0 , projectname: 'All'}].concat(response.data.result);
      })
      .catch(error => {
      })
    },
     loadProjectsForImport() {
     this.$http.get('/wts/get/projects')
      .then(response => {
        let allowed_projects = response.data.vendor_data.filter(x => x.vid == this.vid);
        this.projectData = this.lodash.intersectionBy(response.data.result, allowed_projects, 'projectid');
        this.projectData = this.projectData.length > 0 ? this.projectData.concat([{projectid: 0 , projectname: 'All'}]) : this.projectData;

      })
      .catch(error => {
      })
     },
    exportServicesForMultipleProjecs() {
   if(this.project_id.length == 0) {
        this.$toasted.show("Please Select Project", {
        type: "error",
        duration: "3000",
      });
      return;
    }
    let projects = this.project_id.filter(x=> x.projectid == 0).length == 1 ? this.projectData.filter(x=> x.projectid !== 0) : this.project_id;
    projects.forEach((value, i) => {
      this.exportServices(value.projectid, projects.length);
    });
    },
    async exportServices(projectid, length) {
      const services = await this.loadServicesFromAnotherProject(this.$route.params.id, this.vid);

      this.progressBar = true;
      this.progressBarServicesCount = services.length * length;

      const importServices = await this.importCopyServices(services, projectid, this.vid);
    },
    CopyServiceForSelectedProject() {
     if(this.project_id.length == 0) {
        this.$toasted.show("Please Select Project", {
        type: "error",
        duration: "3000",
      });
      return;
    }
    let projects = this.project_id.filter(x=> x.projectid == 0).length == 1 ? this.projectData.filter(x=> x.projectid !== 0) : this.project_id;
    projects.forEach((value, i) => {
      this.copyServices(value.projectid, projects.length);
    });
    },
    async copyServices(projectid, length) {
      const services = await this.loadServicesFromAnotherProject(projectid, this.vid);

      this.progressBar = true;
      this.progressBarServicesCount = services.length * length;

      const importServices = await this.importCopyServices(services, this.$route.params.id, this.vid);
    },
    importJob (service, vid, id) {
      if(this.progressBar == true){
        this.loadingCount++
        let data = JSON.stringify({
          projectid: id,
          type: service.type,
          name: service.name,
          uom1: service.uom1,
          uom2: service.uom2,
          uom3: service.uom3,
          pu1: service.pu1,
          pu2: service.pu2,
          pu3: service.pu3,
          currency: service.currency,
          uom_volume_status: service.volume != 'N' && service.volume ? true : false
        });
        return this.$http.post('/wts/create/service', data).then((response) => {
          let data = JSON.stringify({
            vid: vid,
            projectid: id,
            serviceid: response.data.id
          })
          this.successServices.push(service.name)
          this.$http.post('/wts/create/vas', data)
        }).then((response) => {
          return service.Code;
        }).catch((error) => {
           this.serviceErrors.push(service.name);
        })
      }
    },
    async importNewServices(services, id, vid){
      if(this.$route.params.id == id && this.importing == false){
        this.$toasted.show('No Services To Import/Export', {type: 'error', duration: '3000'})
        this.progressBar = false;
        this.progressBarServicesCount = 0;
        return
      }

      const that = this;
      var limit = promiseLimit(3)

      Promise.all(services.map((service) => {
        return limit(() => that.importJob(service, vid, id))
      })).then(results => {
        console.log()
        console.log('results:', results)

        if(this.serviceErrors.lenth > 0){
          this.$toasted.show('Some Services Unsuccessful See Below', {type: 'success', duration: '3000'})
        }
        if(this.successServices.lenth > 0){
          this.$toasted.show('Services Successfully Imported', {type: 'success', duration: '3000'})
        }
      })
    },
    importService(data, vid, id){
      var newData = data;

      console.log(data)

      return this.$http.post('/wts/create/service', data).then((response) => {
        let data2 = JSON.stringify({
          vid: vid,
          projectid: id,
          serviceid: response.data.id
        })
        return this.$http.post('/wts/create/vas', data2)
      }).then((response) => {
        return "1";
      }).catch((error) => {
        if(this.vendorSelection.company_name == this.name){
          this.$toasted.show('No Services To Import', {type: 'error', duration: '3000'})
          return
        }
        newData.name = newData.name + " (" + this.vendorSelection.company_name + ")";
        return this.$http.post('/wts/create/service', newData).then((response) => {
          let data2 = JSON.stringify({
            vid: vid,
            projectid: id,
            serviceid: response.data.id
          })
        return this.$http.post('/wts/create/vas', data2)
        }).then((response) => {
          return "1";
        }).catch((error) => {
          console.log(error)
        })
      })
    },
    async displayMessage(responses){
      var count = responses.filter(function (x) {
        return x !== undefined;
      });
      if(count.length > 0){
        this.$toasted.show('Successfully Imported ' + count.length + ' Services', {type: 'success', duration: '3000'})
      }
      if(count.length == 0){
        this.$toasted.show('No Services To Import', {type: 'error', duration: '3000'})
      }
      this.$refs.copyService.hide();
      this.$refs.exportService.hide();
    },
    async importCopyServices(services, id, vid){
      const count = await this.importNewServices(services, id, vid);
    },
    loadServicesFromAnotherProject(projectid, vid) {
      return this.$http.get('/wts/get/services/' + projectid)
      .then((response) => {
        this.services = response.data.result;

        return this.$http.get('/wts/get/vas/' + projectid)
      })
      .then((response) => {
        var temp = response.data.result.filter(x=>x.vid === vid);

        var a = temp;
        var b = this.services;

        var arrResult = _.map(a, function(obj) {
          return _.assign(obj, _.find(b, {
            serviceid: obj.serviceid
          }));
        });

        return arrResult;
      });
    },
    loadData() {

      this.$http.get('/wts/get/services/' + this.$route.params.id)
      .then((response) => {
        this.services = response.data.result;

        return this.$http.get('/wts/get/vas/' + this.$route.params.id)
      })
      .then((response) => {
        var test = response.data.result.filter(x=>x.vid === this.vid);
        var a = test;
        var b = this.services;

        var arrResult = _.map(a, function(obj) {
          return _.assign(obj, _.find(b, {
              serviceid: obj.serviceid
          }));
        });
        this.wtsVendorServiceData = arrResult;
      });
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>
